export const items = [
    {
        title: 'Dashboard',
        icon: 'mdi-view-array',
        group: 'dashboard',
        to: '/'
    },
    {
        title: 'Job Sheets',
        icon: 'mdi-text-box-check-outline',
        to: '/app/jobsheets'
    },
    {
        title: 'Archived Job Sheets',
        icon: 'mdi-archive',
        to: '/app/archivedjobsheets'
    },
    {
        title: 'Installed Devices',
        icon: 'mdi-devices',
        to: '/app/devices'
    },
    {
        title: 'Device Stock',
        icon: 'mdi-warehouse',
        to: '/app/stock'
    },
    {
        title: 'Archived Devices',
        icon: 'mdi-archive',
        to: '/app/archiveddevices'
    },
    {
        title: 'Warehouse Locations',
        icon: 'mdi-store-marker',
        to: '/app/warehouses'
    },
    {
        title: 'Partners',
        icon: 'mdi-handshake-outline',
        to: '/app/partners'
    },
    {
        title: 'Enroll Stock',
        icon: 'mdi-qrcode-scan',
        to: '/app/adddevice'
    },
    {
        title: 'Stock Moves',
        icon: 'mdi-truck',
        to: '/app/stockmoves'
    },
    {
        title: 'Meter Installs',
        icon: 'mdi-text-box-check-outline',
        to: '/app/meters'
    },
    {
        title: 'RAMS',
        icon: 'mdi-flash-alert',
        to: '/app/rams'
    },
    {
        title: 'VO Site Surveys',
        icon: 'mdi-graph-outline',
        to: '/app/vosurveys'
    },
    {
        title: 'VO Comissioning Forms',
        icon: 'mdi-archive',
        to: '/app/vocomissionings'
    },
    {
        title: 'Archived VO Forms',
        icon: 'mdi-graph-outline',
        to: '/app/archivedvocomissionings'
    },
    {
        title: 'VO NECIC Forms',
        icon: 'mdi-graph-outline',
        to: '/app/voniceics'
    },

    // { TODO Handle in occupancy
    //     title: 'Overrides',
    //     icon: 'mdi-clipboard-edit-outline',
    //     to: '/app/carParkOverrides'
    // },
    {
        title: 'System Admin',
        icon: 'mdi-cogs',
        group: 'sysadmin',
        children: [
        ]
    },
]
